import React from "react";
import { EscortAgancy, LogoImage } from "../../assets/images";
import { ReactComponent as EmailSvg } from "../../assets/svg/auth-email.svg";
import { ReactComponent as PasswordSvg } from "../../assets/svg/auth-lock.svg";
import { ReactComponent as UserSvg } from "../../assets/svg/auth-user.svg";

const AgancySignUp = () => {
  return (
    <div className="sign-up-content">
      <div className="image-container">
        <img src={EscortAgancy} alt="Logo EscortHub" />
      </div>

      <div className="form-container">
        <img src={LogoImage} alt="Logo EscortHub" />
        <h1>Escort Agancy Account</h1>
        <h2>Register as an Escort Agancy</h2>

        <form action="">
          <div className="input-wrapper">
            <i className="svg-wrapper">
              <UserSvg />
            </i>
            <input type="text" placeholder="Agancy Name" />
          </div>

          <div className="input-row">
            <div className="input-wrapper">
              <i className="svg-wrapper">
                <PasswordSvg />
              </i>
              <input type="text" placeholder="Password" />
            </div>
            <div className="input-wrapper">
              <i className="svg-wrapper">
                <PasswordSvg />
              </i>
              <input type="text" placeholder="Confirm Password" />
            </div>
          </div>

          <div className="input-wrapper">
            <i className="svg-wrapper">
              <EmailSvg />
            </i>
            <input type="text" placeholder="Email" />
          </div>

          <div className="input-wrapper">
            <i className="svg-wrapper">
              <EmailSvg />
            </i>
            <input type="text" placeholder="Confirm Email" />
          </div>

          <div className="input-wrapper check-box">
            <input type="checkbox" />
            <span>
              I have read and agree to the Terms and Conditions Privacy policy.
            </span>
          </div>

          <div className="input-wrapper check-box">
            <input type="checkbox" />
            <span>
              I have read and agree to the Terms and Conditions Privacy policy.
            </span>
          </div>

          <div className="input-wrapper btn">
            <input type="submit" value="Submit" />
          </div>
        </form>
      </div>
    </div>
  );
};

export default AgancySignUp;
