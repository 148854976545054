import React from "react";
import { EscortCards, Filter, TopBar } from "../components";
import { ReactComponent as SearchSvg } from "../assets/svg/search.svg";

const Home = () => {
  return (
    <>
      <TopBar />
      <main>
        {/* <div className="country-picker">
          <div className="input-wrapper">
            <i className="svg-wrapper">
              <SearchSvg />
            </i>
            <input type="text" placeholder="Search" />
          </div>

          <div className="country-list">
            <div className="country-row">
              <div className="country">Country</div>
              <div className="count">(255)</div>
            </div>

            <div className="country-row">
              <div className="country">Country</div>
              <div className="count">(255)</div>
            </div>

            <div className="country-row">
              <div className="country">Country</div>
              <div className="count">(255)</div>
            </div>
            <div className="country-row">
              <div className="country">Country</div>
              <div className="count">(255)</div>
            </div>
            <div className="country-row">
              <div className="country">Country</div>
              <div className="count">(255)</div>
            </div>
            <div className="country-row">
              <div className="country">Country</div>
              <div className="count">(255)</div>
            </div>
            <div className="country-row">
              <div className="country">Country</div>
              <div className="count">(255)</div>
            </div>
            <div className="country-row">
              <div className="country">Country</div>
              <div className="count">(255)</div>
            </div>
            <div className="country-row">
              <div className="country">Country</div>
              <div className="count">(255)</div>
            </div>
            <div className="country-row">
              <div className="country">Country</div>
              <div className="count">(255)</div>
            </div>
            <div className="country-row">
              <div className="country">Country</div>
              <div className="count">(255)</div>
            </div>
            <div className="country-row">
              <div className="country">Country</div>
              <div className="count">(255)</div>
            </div>
            <div className="country-row">
              <div className="country">Country</div>
              <div className="count">(255)</div>
            </div>
            <div className="country-row">
              <div className="country">Country</div>
              <div className="count">(255)</div>
            </div>
            <div className="country-row">
              <div className="country">Country</div>
              <div className="count">(255)</div>
            </div>
            <div className="country-row">
              <div className="country">Country</div>
              <div className="count">(255)</div>
            </div>
            <div className="country-row">
              <div className="country">Country</div>
              <div className="count">(255)</div>
            </div>
            <div className="country-row">
              <div className="country">Country</div>
              <div className="count">(255)</div>
            </div>
            <div className="country-row">
              <div className="country">Country</div>
              <div className="count">(255)</div>
            </div>
            <div className="country-row">
              <div className="country">Country</div>
              <div className="count">(255)</div>
            </div>
            <div className="country-row">
              <div className="country">Country</div>
              <div className="count">(255)</div>
            </div>
            <div className="country-row">
              <div className="country">Country</div>
              <div className="count">(255)</div>
            </div>
          </div>
        </div> */}
        <div className="main-content">
          <Filter />
          <EscortCards />
        </div>
      </main>
    </>
  );
};

export default Home;
