import React from "react";
import Filter from "../elements/Filter";
import { ReactComponent as SearchSvg } from "../../assets/svg/search.svg";
import { ReactComponent as FiltersSvg } from "../../assets/svg/filters.svg";

const FilterBarWrapper = () => {
  return (
    <div className="filter-bar-wrapper">
      <div className="filters-container">
        <div className="input-wrapper">
          <i className="svg-wrapper">
            <SearchSvg />
          </i>
          <input type="text" placeholder="Search" />
        </div>

        <button className="filter-pop-up-btn">
          Filters <FiltersSvg />
        </button>

        <div className="filters-components-wrapper">
          <Filter />
          <Filter />
          <Filter />
          <Filter />
        </div>
      </div>
    </div>
  );
};

export default FilterBarWrapper;
