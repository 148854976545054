import React from "react";

const SignUpWrapper = ({ children }) => {
  return (
    <div className="sign-up-wrapper">
      <div className="sign-up-container">{children}</div>
    </div>
  );
};

export default SignUpWrapper;
