import React from "react";
import {
  AgancySignUp,
  EscortSignUp,
  MemberSignUp,
  SignUpMethod,
  SignUpWrapper,
} from "../components";
import { Routes, Route } from "react-router-dom";

const SignUp = () => {
  return (
    <SignUpWrapper>
      <Routes>
        <Route path="/" element={<SignUpMethod />} />
        <Route path="/escort" element={<EscortSignUp />} />
        <Route path="/agency" element={<AgancySignUp />} />
        <Route path="/member" element={<MemberSignUp />} />
      </Routes>
    </SignUpWrapper>
  );
};

export default SignUp;
