import React, { useEffect, useState } from "react";
import ImageGallery from "react-image-gallery";
import { ReactComponent as ApprovedSvg } from "../assets/svg/approved.svg";
import { ReactComponent as Phone } from "../assets/svg/phone.svg";
import { ReactComponent as LeftButton } from "../assets/svg/left.svg";
import { ReactComponent as RightButton } from "../assets/svg/right.svg";
import { ReactComponent as InfoSvg } from "../assets/svg/info-info.svg";
import { ReactComponent as LocationSvg } from "../assets/svg/location.svg";
import { ReactComponent as PhoneSvg } from "../assets/svg/phone-info.svg";
import { ReactComponent as RatesSvg } from "../assets/svg/rates-info.svg";
import { ReactComponent as ServicesSvg } from "../assets/svg/services-info.svg";
import { ReactComponent as UserSvg } from "../assets/svg/user-info.svg";
import { ReactComponent as InfoRightArrowSvg } from "../assets/svg/arrow-info.svg";
import { ReactComponent as InfoDownArrowSvg } from "../assets/svg/arrow-down-info.svg";
import { ReactComponent as WhatsAppSvg } from "../assets/svg/whats-app.svg";
import { ReactComponent as TelegramSvg } from "../assets/svg/telegram.svg";

import { TopBar } from "../components";

const images = [
  {
    original:
      "https://cm.dubaiescortstars.com/files/questionary/20936/thumbs/0x650/2ff3961a620b.jpeg?1686342414",
    thumbnail:
      "https://cm.dubaiescortstars.com/files/questionary/20936/thumbs/0x650/2ff3961a620b.jpeg?1686342414",
  },
  {
    original:
      "https://cm.dubaiescortstars.com/files/questionary/20936/thumbs/0x650/50e3a072e302.jpeg?1676072532",
    thumbnail:
      "https://cm.dubaiescortstars.com/files/questionary/20936/thumbs/0x650/50e3a072e302.jpeg?1676072532",
  },
  {
    original:
      "https://cm.dubaiescortstars.com/files/questionary/20936/thumbs/0x650/5f63dffc35a8.jpeg?1676072532",
    thumbnail:
      "https://cm.dubaiescortstars.com/files/questionary/20936/thumbs/0x650/5f63dffc35a8.jpeg?1676072532",
  },
  {
    original:
      "https://cm.dubaiescortstars.com/files/questionary/20936/thumbs/0x650/0d63951d7b4d.jpeg?1676072532",
    thumbnail:
      "https://cm.dubaiescortstars.com/files/questionary/20936/thumbs/0x650/0d63951d7b4d.jpeg?1676072532",
  },
  {
    original:
      "https://cm.dubaiescortstars.com/files/questionary/20936/thumbs/0x650/a96443b7f2a2.jpeg?1676072532",
    thumbnail:
      "https://cm.dubaiescortstars.com/files/questionary/20936/thumbs/0x650/a96443b7f2a2.jpeg?1676072532",
  },
  {
    original:
      "https://cm.dubaiescortstars.com/files/questionary/20936/thumbs/0x650/5cda89ba05bb.jpeg?1676072532",
    thumbnail:
      "https://cm.dubaiescortstars.com/files/questionary/20936/thumbs/0x650/5cda89ba05bb.jpeg?1676072532",
  },
  {
    original:
      "https://cm.dubaiescortstars.com/files/questionary/20936/thumbs/0x650/6107983a93fa.jpeg?1676072532",
    thumbnail:
      "https://cm.dubaiescortstars.com/files/questionary/20936/thumbs/0x650/6107983a93fa.jpeg?1676072532",
  },
];

const SingleEscort = () => {
  const [expandAbout, setExpandAbout] = useState(false);
  const [expandInfor, setExpandInfo] = useState(false);
  const [expandServices, setExpandServices] = useState(false);
  const [expandRates, setExpandRates] = useState(false);
  const [expandContact, setExpandContact] = useState(false);

  const [screenSize, setScreenSize] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 575) {
        setScreenSize(true);
      } else {
        setScreenSize(false);
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  return (
    <>
      <TopBar />
      <div className="single-page-wrapper">
        <div className="single-page-content">
          <div className="previous-next-btton">
            <button>
              <LeftButton />
              previous model
            </button>
            <button>
              next model
              <RightButton />
            </button>
          </div>
          <div className="left-container">
            <div className="image-gallery-container">
              <ImageGallery
                showFullscreenButton={false}
                showPlayButton={false}
                items={images}
              />
            </div>
          </div>

          <div className="right-container">
            <div className="escort-details-container">
              <h1>
                Anjela <ApprovedSvg />
              </h1>
              <span>Caneda</span>
            </div>

            <div className="description-container">
              <button
                className="top-section-details"
                onClick={() => screenSize && setExpandAbout(!expandAbout)}
              >
                <i>
                  <InfoSvg />
                </i>
                <span className="top-section-header">About</span>
                {!expandAbout ? <InfoRightArrowSvg /> : <InfoDownArrowSvg />}
              </button>

              <p
                style={{
                  display: !expandAbout && screenSize ? "none" : "block",
                }}
              >
                Lorem ipsum dolor, sit amet consectetur adipisicing elit. Iure,
                officiis quas dolorem perspiciatis praesentium temporibus
                doloremque ab? Commodi repellendus, temporibus voluptates
                doloremque perferendis repudiandae quaerat neque magni molestias
                officiis deleniti! Dolorem obcaecati deleniti suscipit
                accusantium sit aliquid quae exercitationem quidem. Placeat illo
                corrupti perspiciatis eius voluptatibus nesciunt ratione
                distinctio praesentium perferendis blanditiis id quo esse
                pariatur optio voluptas, officia soluta? Quis ad ab laboriosam
                quidem, quisquam cum. Dolorem saepe ducimus accusamus eum qui
                sunt suscipit doloribus officia possimus perspiciatis optio
                deleniti, temporibus, expedita voluptates aut earum molestias
                velit necessitatibus ipsum. Dolorum ipsam enim labore velit
                voluptates iusto perferendis repellat quaerat consectetur error!
                Expedita eos fugiat iure dolores eligendi, tempora, maxime
                recusandae, dolorum esse quaerat tenetur vitae rerum aliquid
                ipsum officiis!
              </p>
            </div>

            <div className="details-table">
              <button
                className="top-section-details"
                onClick={() => screenSize && setExpandInfo(!expandInfor)}
              >
                <i>
                  <UserSvg />
                </i>
                <span className="top-section-header">Details</span>
                {!expandInfor ? <InfoRightArrowSvg /> : <InfoDownArrowSvg />}
              </button>

              <div
                className="details-table-grid-wrapper"
                style={{
                  display: !expandInfor && screenSize ? "none" : "flex",
                }}
              >
                <div className="details-table-grid">
                  <div className="details-table-row">
                    <span className="heading">FFFFF</span>
                    <span className="data">SSSS</span>
                  </div>

                  <div className="details-table-row">
                    <span className="heading">FFFFF</span>
                    <span className="data">SSSS</span>
                  </div>

                  <div className="details-table-row">
                    <span className="heading">FFFFF</span>
                    <span className="data">SSSS</span>
                  </div>

                  <div className="details-table-row">
                    <span className="heading">FFFFF</span>
                    <span className="data">SSSS</span>
                  </div>
                </div>
                <div className="details-table-grid">
                  <div className="details-table-row">
                    <span className="heading">FFFFF</span>
                    <span className="data">SSSS</span>
                  </div>

                  <div className="details-table-row">
                    <span className="heading">FFFFF</span>
                    <span className="data">ffffffffff</span>
                  </div>

                  <div className="details-table-row">
                    <span className="heading">FFFFF</span>
                    <span className="data">SSSS</span>
                  </div>

                  <div className="details-table-row">
                    <span className="heading">FFFFF</span>
                    <span className="data">SSSS</span>
                  </div>
                </div>
              </div>
            </div>

            <div className="details-table">
              <button
                className="top-section-details"
                onClick={() => screenSize && setExpandServices(!expandServices)}
              >
                <i>
                  <ServicesSvg />
                </i>
                <span className="top-section-header">Services</span>
                {!expandServices ? <InfoRightArrowSvg /> : <InfoDownArrowSvg />}
              </button>

              <div
                className="service-table"
                style={{
                  display: !expandServices && screenSize ? "none" : "flex",
                }}
              >
                <div>Service 1</div>
                <div>Service 1</div>
                <div>Service 1</div>
                <div>Service 1</div>
                <div>Service 1</div>
                <div>Service 1</div>
                <div>Service 1</div>
                <div>Service 1</div>
                <div>Service 1</div>
                <div>Service 1</div>
                <div>Service 1</div>
                <div>Service 1</div>
                <div>Service 1</div>
              </div>
            </div>

            <div className="details-table">
              <button
                className="top-section-details"
                onClick={() => screenSize && setExpandRates(!expandRates)}
              >
                <i>
                  <RatesSvg />
                </i>
                <span className="top-section-header">Rates</span>
                {!expandRates ? <InfoRightArrowSvg /> : <InfoDownArrowSvg />}
              </button>

              <div
                className="details-table-grid-wrapper"
                style={{
                  display: !expandRates && screenSize ? "none" : "flex",
                }}
              >
                <div className="details-table-grid">
                  <div className="details-table-row">
                    <span className="heading">FFFFF</span>
                    <span className="data">SSSS</span>
                  </div>

                  <div className="details-table-row">
                    <span className="heading">FFFFF</span>
                    <span className="data">SSSS</span>
                  </div>

                  <div className="details-table-row">
                    <span className="heading">FFFFF</span>
                    <span className="data">SSSS</span>
                  </div>

                  <div className="details-table-row">
                    <span className="heading">FFFFF</span>
                    <span className="data">SSSS</span>
                  </div>
                </div>
              </div>
            </div>

            <div className="details-table">
              <button
                className="top-section-details"
                onClick={() => screenSize && setExpandContact(!expandContact)}
              >
                <i>
                  <PhoneSvg />
                </i>
                <span className="top-section-header">Contact</span>
                {!expandContact ? <InfoRightArrowSvg /> : <InfoDownArrowSvg />}
              </button>

              <div
                className="button-wrapper"
                style={{
                  display: !expandContact && screenSize ? "none" : "flex",
                }}
              >
                <a href="https://phone">
                  <WhatsAppSvg />
                </a>
                <a href="https://phone">
                  <TelegramSvg />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SingleEscort;
