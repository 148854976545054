import React from "react";
import EscortCard from "../cards/EscortCard";

const CardWrapper = () => {
  return (
    <div className="escort-card-wrapper">
      <div className="escort-card-container">
        <EscortCard />
        <EscortCard />
        <EscortCard />
        <EscortCard />
        <EscortCard />
        <EscortCard />
        <EscortCard />
        <EscortCard />
      </div>
    </div>
  );
};

export default CardWrapper;
