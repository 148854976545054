import React from "react";
import { ReactComponent as DropDownSvg } from "../../assets/svg/dropdown.svg";

const Filter = () => {
  return (
    <div className="filter-component">
      <button className="filter-component-input-wrapper">
        <div>Test</div>
        <DropDownSvg />
      </button>
    </div>
  );
};

export default Filter;
