import React, { useState } from "react";
import { ReactComponent as HamburgerSvg } from "../../assets/svg/hamburger.svg";
import { ReactComponent as ProfileSvg } from "../../assets/svg/profile.svg";
import { ReactComponent as LocationSvg } from "../../assets/svg/location.svg";

import { LogoImage } from "../../assets/images";
import { useNavigate } from "react-router-dom";

const TopBar = () => {
  const navigate = useNavigate();
  const [openAuth, setOpenAuth] = useState(false);

  return (
    <div className="nav-bar-wrapper">
      <img src={LogoImage} alt="Logo EscortHub" />

      {/* <button  className="menu-toggle">
        <i className="svg-wrapper">
          <HamburgerSvg />
        </i>
      </button> */}

      {/* <button className="country-picker-toggle">
        <i className="svg-wrapper">
          <LocationSvg />
        </i>
      </button> */}

      <button className="profile-toggle" onClick={() => setOpenAuth(!openAuth)}>
        <i className="svg-wrapper">
          <ProfileSvg />
        </i>
      </button>

      <div
        className="auth-wrapper"
        style={{ display: openAuth ? "grid" : "none" }}
      >
        <span
          className="glasspane"
          onClick={() => setOpenAuth(!openAuth)}
        ></span>
        <div>
          <button onClick={() => navigate("/auth/signup")}>
            Create an account ?{" "}
          </button>

          <div className="login-wrapper">
            <input type="text" placeholder="user name" />
            <input type="text" placeholder="password" />
            <button>Login</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopBar;
