import React from "react";
import { Routes, Route } from "react-router-dom";
import SignUp from "./SignUp";

const Auth = () => {
  return (
    <Routes>
      <Route path="/login" element={<></>} />
      <Route path="/signup/*" element={<SignUp />} />
    </Routes>
  );
};

export default Auth;
