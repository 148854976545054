import { Home, SingleEscort } from "./pages";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Auth from "./pages/Auth";

const App = () => {
  return (
    <div className="wrapper">
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/escort" element={<SingleEscort />} />
          <Route path="/auth/*" element={<Auth />} />
        </Routes>
      </Router>
    </div>
  );
};

export default App;
