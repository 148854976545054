import React from "react";
import { ReactComponent as ApprovedSvg } from "../../assets/svg/approved.svg";
import { useNavigate } from "react-router-dom";

const EscortCard = () => {
  const navigate = useNavigate();

  return (
    <article onClick={() => navigate("/escort")} className="escort-card">
      <img
        src="https://cm.dubaiescortstars.com/files/questionary/20895/thumbs/0x650/586ed1e2a551.jpeg?1686506684"
        alt="images"
      />
      <div className="personal-details">
        <div>
          <span>Joana</span>
          <ApprovedSvg />
        </div>
        <span>
          <span>Canadian</span> - <span>25 Years</span>
        </span>
      </div>

      <div className="description">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
        veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
        commodo consequat.
      </div>
    </article>
  );
};

export default EscortCard;
