import React from "react";
import { LogoImage } from "../../assets/images";
import { useNavigate } from "react-router-dom";

const SignUpMethod = () => {
  const navigate = useNavigate();

  return (
    <div className="select-sign-up-method">
      <img src={LogoImage} alt="Logo EscortHub" />

      <h1>select your account type</h1>

      <div className="card-wrapper">
        <div className="card">
          <span className="heading">INDEPENDENT ESCORT</span>

          <span className="body">Register as an individual escort</span>

          <button onClick={() => navigate("escort")}>Sign Up</button>
        </div>

        <div className="card">
          <span className="heading">ESCORT Agancy</span>

          <span className="body">Register as an escort agancy</span>

          <button onClick={() => navigate("agency")}>Sign Up</button>
        </div>

        <div className="card">
          <span className="heading">Member</span>

          <span className="body">Register as a member</span>

          <button onClick={() => navigate("member")}>Sign Up</button>
        </div>
      </div>
    </div>
  );
};

export default SignUpMethod;
